import React from "react";
import Dashboard from "./Components/Dashboard";
import './App.css'
const App = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default App;
